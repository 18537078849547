<template>
  <div class="" style="width: 100%;">
    <v-row>
      <v-col>
        <v-container fluid>
          <v-row>
            <v-col class="d-flex flex-row align-center">
              <h1>{{this.plural}}</h1>
              <v-btn small fab color="info" @click="openCreateInvoiceDialog" class="ml-2"><v-icon>mdi-plus</v-icon></v-btn>
              <v-btn class="ml-2" color="info" @click="$router.push('/orders/filterByProduct')">Filter by Product</v-btn>
              <v-checkbox v-if="getGlobalValue('VEC_FILTER_ORDERS_AS_ON_ACCOUNT')==='true'" label="Show Only Orders On Account" v-model="options.showOnAccountOnly"/>
              <v-progress-circular
                  indeterminate
                  color="green"
                  v-if="loader"
                  style="margin-left: 10px;"
              ></v-progress-circular>
            </v-col>
          </v-row>
          <v-row v-if="getGlobalValue('VEC_INCLUDES_ORDER_SERIAL_NUMBERS_STORAGE_AND_QUERYING')==='true'">      
            <v-col>
              <span class="d-flex flex-row align-center" style="width: 500px">
                <v-text-field hide-details dense outlined :label="`Search Bulk ${serialNaming.plural}`" v-model="serialSearch"></v-text-field>
                <v-btn class="ml-2" color=info small @click="searchBulkSerial" :disabled="!serialSearch || !serialSearch.trim()" fab><v-icon>mdi-magnify</v-icon></v-btn>
              </span>
              <span v-if="serialSearchResults && serialSearchResults.length > 0">
                <b>Found {{ serialSearchResults.length }} {{ serialSearchResults.length > 1 ? "documents" : "document" }} containing the term "{{ serialSearch }}"</b>
                <div v-for="(result, index) in serialSearchResults" :key="index">
                  <router-link :to="'/orders/view/'+result">{{singular}} #{{ result }}</router-link>
                </div>
              </span>
            </v-col>
          </v-row>
          <v-row class="mt-n8 mb-n8">
            <v-col class="d-flex flex-row align-center">
              <v-text-field hint="Longer and better typed search terms may give more results." persistent-hint class="mr-2 mt-5" clearable dense outlined label="Search Order Notes" @change="searchOrderNotes" :loading="orderNotesSearch.loading" v-model="orderNotesSearch.value"></v-text-field>
              <v-text-field style="width: min-content;" dense outlined label="Go to Order #" @change="gotoOrder(orderIdSearch)" class="mt-5" v-model="orderIdSearch"></v-text-field>
              <!-- <v-btn color=info small @click="gotoOrder(orderIdSearch)" :disabled="!orderIdSearch || !orderIdSearch.trim()">Search</v-btn> -->
              <v-btn @click="gotoOrder('lastSealed')" :loading="gotoOrderLoading" color="info" text small class="mr-3">View Last Sealed</v-btn>
              <v-btn @click="gotoOrder('lastSealedByMe')" :loading="gotoOrderLoading" color="info" text small class="mr-3">View Last Sealed By Me</v-btn>
              <v-btn v-if="isAllowed('order', 'voidInvoice')" @click="gotoOrder('lastVoided')" :loading="gotoOrderLoading" color="info" text small>View Last Voided</v-btn>
              <v-btn v-if="isAllowed('order', 'voidInvoice')" @click="gotoOrder('lastVoidedByMe')" :loading="gotoOrderLoading" color="info" text small>View Last Voided By Me</v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col v-if="!loader">
              <v-data-table
                  :headers="headers"
                  :items="allData"
                  :options.sync="options"
                  :server-items-length="pagination.dataCount"
                  :loading="pagination.loading"
                  :footer-props="{
                    itemsPerPageOptions: pagination.pageSizeOptions
                  }"
                  class="elevation-1"
                  @click:row="rowClick"
              >
                <template v-slot:item.customerName="{ item }">
                  <span>{{ item.customerName?item.customerName:"-" }}</span>
                </template>
                <template v-slot:item.csrUser="{ item }">
                  <span v-if="item.createdBy">{{lookupUsername(item.createdBy)}}</span>
                  <span v-else>-</span>
                </template>
                <template v-slot:item.status="{ item }">
                  <v-chip :color="utils.getStatusColor(item.status)">{{utils.parseStatus(item.status)}}</v-chip>
                </template>
                <template v-slot:item.sealedAt="{ item }">
                  <span>{{utils.formatDate(item.sealedAt, 'withTime')}}</span>
                </template>
                <template v-if="getGlobalValue('VEC_INCLUDES_AR')==='true'" v-slot:item.daysLate="{ item }">
                  <span v-if="!item.daysLate" >{{item.daysLate!=0?'-':0}}</span>
                  <v-chip  color="green" small v-if="item.daysLate && item.daysLate < 30 " style="color:white;">{{item.daysLate}}</v-chip>
                  <v-chip  color="orange" small v-else-if="item.daysLate && item.daysLate < 60 " style="color:white;">{{item.daysLate}}</v-chip>
                  <v-chip  color="red" small v-else-if="item.daysLate && item.daysLate >= 60 " style="color:white;">{{item.daysLate}}</v-chip>
                </template>
                <template v-slot:item.actions="{ item }">
                  <v-btn class="mr-1" v-if="isAllowed('order', 'r')" fab x-small color="info" @click="rowClick(item)">
                    <v-icon>mdi-file</v-icon>
                  </v-btn>
                </template>
                <template v-slot:item.metadata.grandTotal="{ item }">
                  <span v-if="item.metadata&&item.metadata.grandTotal">{{ utils.formatCurrency(item.metadata.grandTotal?item.metadata.grandTotal:0) }}</span>
                  <span v-else>-</span>
                </template>
                <template v-slot:item.balance="{ item }">
                  <span v-if="item.balance || (getGlobalValue('VEC_INCLUDES_HP')==='true' && !item?.metadata.useHP)">{{ utils.formatCurrency(item.balance?item.balance:0) }}</span>
                  <span v-else>-</span>
                </template>
                <template v-slot:item.returns="{ item }">
                  <span v-if="item.Returns && item.Returns.length > 0">YES</span>
                  <span v-else>NO</span>
                </template>
                <template v-slot:item.deliveryType="{ item }">
                  <span v-if="item.deliveryInfo && item.deliveryInfo.deliveryType">{{ item?.deliveryInfo.deliveryType.toUpperCase()}}</span>
                </template>
                <template v-slot:item.isHP="{ item }">
                  <span>{{ item?.metadata.useHP?"YES":"NO"}}</span>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>

    <v-dialog
      v-model="createInvoiceDialog.isOpen"
      max-width="490"
      fixed
    >
      <v-card class="d-flex flex-column align-center justify-center">
        <v-card-title class="text-h5">
          <b class="text-center">Confirm Create Order</b>
        </v-card-title>
        <v-card-actions>
          <v-btn color="error" text @click="closeCreateInvoiceDialog">Cancel</v-btn>
          <v-btn color="success" :loading="createInvoiceDialog.loading" @click="attemptCreateInvoice()" ref="confirmBtn">Confirm</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="orderNotesSearch.isOpen">
      <v-card>
        <v-card-title>Order Notes Search ({{orderNotesSearch.data.length}} Results)</v-card-title>
        <v-card-text>
          <v-text-field hint="Longer search terms and better typed may give more results." persistent-hint class="mr-2" clearable style="" dense outlined label="Search Order Notes" @change="searchOrderNotes" :loading="orderNotesSearch.loading" v-model="orderNotesSearch.value"></v-text-field>
          <v-data-table
              :headers="headersNotes"
              :items="orderNotesSearch.data"
              class="elevation-1"
              @click:row="rowClick"
          >
            <template v-slot:item.customerName="{ item }">
              <span>{{ item.customerName?item.customerName:"-" }}</span>
            </template>
            <template v-slot:item.csrUser="{ item }">
              <span v-if="item.createdBy">{{lookupUsername(item.createdBy)}}</span>
              <span v-else>-</span>
            </template>
            <template v-slot:item.status="{ item }">
              <v-chip :color="utils.getStatusColor(item.status)">{{utils.parseStatus(item.status)}}</v-chip>
            </template>
            <template v-slot:item.sealedAt="{ item }">
              <span>{{utils.formatDate(item.sealedAt, 'withTime')}}</span>
            </template>
            <template v-slot:item.metadata.grandTotal="{ item }">
              <span v-if="item.metadata&&item.metadata.grandTotal">{{ utils.formatCurrency(item.metadata.grandTotal?item.metadata.grandTotal:0) }}</span>
              <span v-else>-</span>
            </template>
            <template v-slot:item.balance="{ item }">
              <span v-if="item.balance || (getGlobalValue('VEC_INCLUDES_HP')==='true' && !item?.metadata.useHP)">{{ utils.formatCurrency(item.balance?item.balance:0) }}</span>
              <span v-else>-</span>
            </template>
            <template v-slot:item.deliveryType="{ item }">
              <span v-if="item.deliveryInfo && item.deliveryInfo.deliveryType">{{ item?.deliveryInfo.deliveryType.toUpperCase()}}</span>
            </template>
            <template v-slot:item.metadata.note="{ item }">
              <span>{{ item?.metadata.note}}</span>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-snackbar v-model="snackObj.state" :timeout="3000" :color="snackObj.color">
      {{ snackObj.text }}
      <template v-slot:action="{ attrs }">
        <v-btn v-bind="attrs" text @click="snackObj.state = false">Close</v-btn>
      </template>
    </v-snackbar>

  </div>
</template>
<script>
  import axios from 'axios';
  import {mapGetters, mapMutations} from "vuex";
  import utils from "./../../plugins/helpers"
  export default {
    data () {
      return {
        utils:utils,
        serialSearch: '',
        serialSearchResults: [],

        deleteDialog: false,
        deleteConfirmed: true,

        gotoOrderLoading: false,
        orderIdSearch: '',

        orderNotesSearch: {
          value: '',
          loading: false,
          isOpen: false,
          data: []
        },

        loader: true,
        snackObj: {
          state: false,
          color: '',
          text: ''
        },
        max25chars: v => v.length <= 25 || 'Input too long!',
        headers: [
          {
            text: '#',
            align: 'start',
            sortable: true,
            value: 'id',
          },
          { text: 'Status', value: 'status' },
          { text: 'Date Sealed', value: 'sealedAt' },
          { text: 'Customer', value: 'customerName', sortable: false },
          { text: 'CSR', value: 'csrUser', sortable: false },
          { text: 'Delivery Type', value: 'deliveryType', sortable: false },
          { text: '# Items', value: 'itemCount', sortable: false},
          { text: 'Grand Total', value: 'metadata.grandTotal', sortable: false },
          { text: 'Balance', value: 'balance', sortable: false },
          { text: 'Returns?', value: 'returns' },
          { text: 'HP', value: 'isHP'}
          // { text: 'Actions', value: 'actions' },
        ],
        headersNotes: [
          {
            text: '#',
            align: 'start',
            sortable: true,
            value: 'id',
          },
          { text: 'Status', value: 'status' },
          { text: 'Date Sealed', value: 'sealedAt' },
          { text: 'Customer', value: 'customerName', sortable: false },
          { text: 'CSR', value: 'csrUser', sortable: false },
          { text: 'Grand Total', value: 'metadata.grandTotal', sortable: false },
          { text: 'Balance', value: 'balance', sortable: false },
          { text: 'Notes', value: 'metadata.note', sortable: false }
        ],
        itemHeaders: [
          { text: 'SKU', value: 'sku' },
          { text: 'Name', value: 'name' },
          { text: 'QTY', value: 'quantity' },
          { text: 'Unit Price', value: 'unitPrice' },
          { text: 'Line Total', value: 'lineTotal' },
        ],
        editMode: false,

        selectedOrder: null,

        pagination: {
          loading: false,
          dataCount: 0,
          pageSizeOptions: [5, 10, 15, 30, -1]
        },
        options: {
          sortBy: ['id'],
          sortDesc: [false],
          page: 1,
          itemsPerPage: 15,
          showOnAccountOnly: false
        },
        updatingRoute: false,
        init: false,

        data: {},
        allData: [],
        singular: "Order",
        singularLower: "order",
        plural: "Orders",
        pluralLower: "orders",

        createInvoiceDialog: {
          isOpen: false,
          customerId: '',
          loading: false,
        },
        rowClicks: 1
      }
    },
    async created(){
      try{
        //used for pagination
        let oldQuery = this.getURLQuery();
        this.options.page = oldQuery.page || 1;
        this.options.itemsPerPage = oldQuery.limit || 15
        this.options.sortBy = [oldQuery.sort || "id"]
        this.options.sortDesc = [oldQuery.order || false]
        this.options.showOnAccountOnly = oldQuery.showOnAccountOnly || false
        //used for pagination end
      }
      catch (error) {
        console.error(error)
        this.snack(error.msg || error.msg?.message || error, "error");
      }
    },
    async mounted(){
      this.init = true;

      this.pagination.pageSizeOptions = this.getGlobalValue("paginationPageLimitsGeneral")?.split(",").map(x => parseInt(x)) || this.pagination.pageSizeOptions;

      await this.getAllData();
      this.init = false;
    },
    computed: {
      ...mapGetters(['getUsername', 'getEndpoint', 'isAllowed', 'getPerms', 'getId', 'lookupUsername', 'getGlobalValue', 'serialNaming'])
    },
    watch: {
      options: {
        async handler () {
          await this.getAllData();
        },
        deep: true,
      },
      $route: {
        handler: async function (current) {
          //used for pagination
          if (this.updatingRoute) return;
          let oldQuery = this.getURLQuery(current.query);
          this.options.page = oldQuery.page || 1;
          this.options.itemsPerPage = oldQuery.limit || 15
          this.options.sortBy = [oldQuery.sort || "id"]
          this.options.sortDesc = [oldQuery.order || false]
          this.options.showOnAccountOnly = oldQuery.showOnAccountOnly || false
          //used for pagination end include full watcher
        },
        deep: true
      }
    },
    methods: {
      ...mapMutations(["setSnack"]),
      
      snack(text, color=""){
        this.snackObj.text = text;
        this.snackObj.state = true;
        this.snackObj.color = color;
      },

      async gotoOrder(x){
        try {
          this.gotoOrderLoading = true

          if(!x) return

          let res = await axios.get(`${this.getEndpoint}/api/${this.pluralLower}/gotoOrder/${x}`)
          if(res.data.error) throw res.data.error
          this.serialSearchResults = res.data.data
          await this.$router.push({path: `/orders/view/${res.data.data}`});

        } catch (error) {
          console.log(error)
          this.snack(error.msg)
        } finally {
          this.gotoOrderLoading = false
        }
      },
      async rowClick(row){
        await this.$router.push({path: `/orders/view/${row.id}`});
      },
      async closeRow(){
        await this.$router.replace({path: `/${this.pluralLower}`, query: null});
        this.selectedOrder = null;
      },
      openOrder(row){
        this.$router.push({ path: `/${this.pluralLower}/view/${row.id}`})
      },
      openCustomer(row){
        this.$router.push({ path: `/customers/view/${row}`})
      },
      openEmployee(row){
        this.$router.push({ path: `/users/view/${row}`})
      },
      async searchBulkSerial(){
        try{
          this.loader = true
          let res = await axios.post(`${this.getEndpoint}/api/${this.pluralLower}/searchBulkSerial`, {query: this.serialSearch})
          if(res.data.error) throw res.data.error
          this.serialSearchResults = res.data.data
        } catch (error) {
          console.error(error);
        } finally {
          this.loader = false;
        }
      },
      clearSerialSearchResults(){
        this.serialSearchResults = []
        this.serialSearch = ''
      },      
      async getAllData(){
        try {

          if(this.getGlobalValue('VEC_INCLUDES_HP')!=='true'){
            let ix = this.headers.findIndex(x=>x.value=="isHP")
            if(ix != -1) this.headers.splice(ix,1)
          }

          //used for pagination
          this.pagination.loading = true;
          let paginationData = {
            page: this.options.page,
            limit: this.options.itemsPerPage,
            sort: (this.options.sortBy.length>0)?this.options.sortBy[0]:"id",
            order: this.options.sortDesc[0],
            showOnAccountOnly: this.options.showOnAccountOnly
          }
          let uriFields = Object.keys(paginationData).map(x => {
            return x + "=" + (paginationData[x]!==undefined?encodeURIComponent(paginationData[x]):'')
          }).join("&");
          //used for pagination end

          if(this.getGlobalValue('VEC_INCLUDES_AR')!=='true'){
            let ix = this.headers.findIndex(x=>x.value=="daysLate")
            if(ix != -1) this.headers.splice(ix,1)
          }
          let res = await axios.get(`${this.getEndpoint}/api/${this.pluralLower}/tableDataPaginated?${uriFields}`)
          if(res.data.error) throw res.data.error
          this.allData = res.data.data

          // EXPERIMENT 30/08/2023 DP
          // I am exploring ways to limit low level employees from having blatant access to all previous orders.
          // In this experiment, i am filtering out all sealed orders, based on perms
          if(!this.isAllowed('order', 'viewAll')){
            this.allData = this.allData.filter(x=>x.status == 0)
          }

          // END OF EXPERIMENT
          
          
          //used for pagination
          this.pagination.dataCount = res.data.total

          let shouldUpdate = false;
          let oldQuery = this.getURLQuery();
          let newQuery = {...paginationData};
          if(Object.keys(newQuery).length!==Object.keys(oldQuery).length) shouldUpdate = true;
          if(!shouldUpdate){
            for(let key of Object.keys(newQuery)){
              if(shouldUpdate) break;

              if(Array.isArray(newQuery[key])){
                if(newQuery[key].length!==oldQuery[key].length){
                  shouldUpdate = true;
                  break;
                }
                for(let i=0;i<newQuery[key].length-1;i++){
                  if(newQuery[key][i]!==oldQuery[key][i]){
                    shouldUpdate = true;
                    break;
                  }
                }
              }
              else if(newQuery[key]!==oldQuery[key]){
                shouldUpdate = true;
              }
            }
          }

          if(shouldUpdate){
            this.updatingRoute = true;
            if(this.init){
              await this.$router.replace({path: `/${this.pluralLower}`, query: newQuery});
            }
            else{
              if(this.$route.path===`/${this.pluralLower}`) await this.$router.push({path: `/${this.pluralLower}`, query: newQuery});
            }
            this.updatingRoute = false;
          }
          //used for pagination end

        } catch (error) {
          console.error(error);
        } finally {
          this.pagination.loading = false;
          this.loader = false;
        }
      },
      //used for pagination
      getURLQuery(custom=null){
        let oldQuery = custom? {...custom}: {...this.$route.query};
        if(oldQuery.limit) oldQuery.limit = parseInt(oldQuery.limit);
        if(oldQuery.page) oldQuery.page = parseInt(oldQuery.page);
        if(oldQuery.order) oldQuery.order = oldQuery.order==='true';
        if(oldQuery.showOnAccountOnly) oldQuery.showOnAccountOnly = oldQuery.showOnAccountOnly==='true';
        return oldQuery;
      },
      //used for pagination end
      openCreateInvoiceDialog(){
        this.createInvoiceDialog.isOpen = true
        setTimeout(() => {
          this.$refs.confirmBtn.$el.focus()
          this.$forceUpdate()
        })
      },
      closeCreateInvoiceDialog(){
        this.createInvoiceDialog.isOpen = false
        this.createInvoiceDialog.loading = false
      },
      async attemptCreateInvoice(){
        try {
          this.createInvoiceDialog.loading = true
          let res = await axios.post(`${this.getEndpoint}/api/orders`, {createdBy: this.getId})
          if(res.data.error) throw res.data.error
          await this.$router.push({path: `/orders/view/${res.data.data.id}`})
        } catch (error) {
          console.log(error)
        } finally{
          this.createInvoiceDialog.loading = false
        }
      },
      parseStatus(id){
        if(id===-1) return "Voided"
        else if(id===0) return "Draft"
        else if(id===1) return "Sealed"
        else if(id===2) return "Admin Sealed"
        else if(id===3) return "Delivery Scheduled"
        else if(id===4) return "Pending Reschedule"
        else if(id===5) return "Out For Delivery"
        else if(id===6) return "Delivered"
      },
      async searchOrderNotes(){
        try {
          this.orderNotesSearch.loading = true

          if(!this.orderNotesSearch.value.trim()) return

          let paginationData = {
            value: this.orderNotesSearch.value.trim()
          }

          let uriFields = Object.keys(paginationData).map(x => {
            return x + "=" + (paginationData[x]!==undefined?encodeURIComponent(paginationData[x]):'')
          }).join("&");

          let res = await axios.get(`${this.getEndpoint}/api/${this.pluralLower}/searchOrderNotes?${uriFields}`)
          if(res.data.error) throw res.data.error
          this.orderNotesSearch.data = res.data.data

          this.orderNotesSearch.isOpen = true;

        } catch (error) {
          console.log(error)
          this.snack(error.msg)
        } finally {
          this.orderNotesSearch.loading = false
        }
      },
    }
  }
</script>
